<template lang="pug">
  div(style="max-width: 540px; margin: auto")
    h1 {{$t('events.title')}} ({{sortedEvents.length}})
    .py-2

    Divider
    v-list.py-0(dense)
      template(v-for="event, i in sortedEvents")
        Divider(v-if="i", inset=154)
        Event(:id="event.id")

    Divider
    .py-2

</template>

<script>
import Event from './Event';

export default {
  name: 'Events',

  components: { Event },

  computed: {
    sortedEvents() {
      let events = Object.values(this.$db.events).filter(event => {
        if (event.startAt > new Date().valueOf()) return false;

        return true;
      });

      events.sort((a, b) => b.startAt - a.startAt);
      return events;
    }
  }
};
</script>
